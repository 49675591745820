export const tokens = {
  common: {
    languageChanged: "common.languageChanged",
  },
  nav: {
    account: "nav.account",
    projects: "nav.projects",
    warehouse: "nav.warehouse",
    overview: "nav.overview",
  },
};
