import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

export const TenantSwitch = (props) => {
  return (
    <>
      <Stack alignItems="center" direction="row" spacing={2} {...props}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography color="inherit" variant="h6">
            OMT
          </Typography>
          <Typography color="neutral.400" variant="body2">
            Production
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

TenantSwitch.propTypes = {
  sx: PropTypes.object,
};
