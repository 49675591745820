import { SvgIcon } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import BarChartSquare02Icon from "src/icons/untitled-ui/duocolor/bar-chart-square-02";
import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import LineChartUp04Icon from "src/icons/untitled-ui/duocolor/line-chart-up-04";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";

export const useSections = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: t(tokens.nav.overview),
            path: paths.dashboard.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.projects),
            path: paths.dashboard.analytics,
            icon: (
              <SvgIcon fontSize="small">
                <BarChartSquare02Icon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.warehouse),
            path: paths.dashboard.ecommerce,
            icon: (
              <SvgIcon fontSize="small">
                <LineChartUp04Icon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.account),
            path: paths.dashboard.account,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },
        ],
      },

      // {
      //   subheader: t(tokens.nav.pages),
      //   items: [
      //     {
      //       title: t(tokens.nav.auth),
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Lock01Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.login),
      //           items: [
      //             {
      //               title: "Classic",
      //               path: paths.authDemo.login.classic,
      //             },
      //             {
      //               title: "Modern",
      //               path: paths.authDemo.login.modern,
      //             },
      //           ],
      //         },
      //         {
      //           title: t(tokens.nav.register),
      //           items: [
      //             {
      //               title: "Classic",
      //               path: paths.authDemo.register.classic,
      //             },
      //             {
      //               title: "Modern",
      //               path: paths.authDemo.register.modern,
      //             },
      //           ],
      //         },
      //         {
      //           title: t(tokens.nav.forgotPassword),
      //           items: [
      //             {
      //               title: "Classic",
      //               path: paths.authDemo.forgotPassword.classic,
      //             },
      //             {
      //               title: "Modern",
      //               path: paths.authDemo.forgotPassword.modern,
      //             },
      //           ],
      //         },
      //         {
      //           title: t(tokens.nav.resetPassword),
      //           items: [
      //             {
      //               title: "Classic",
      //               path: paths.authDemo.resetPassword.classic,
      //             },
      //             {
      //               title: "Modern",
      //               path: paths.authDemo.resetPassword.modern,
      //             },
      //           ],
      //         },
      //         {
      //           title: t(tokens.nav.verifyCode),
      //           items: [
      //             {
      //               title: "Classic",
      //               path: paths.authDemo.verifyCode.classic,
      //             },
      //             {
      //               title: "Modern",
      //               path: paths.authDemo.verifyCode.modern,
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       title: t(tokens.nav.pricing),
      //       path: paths.pricing,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <CreditCard01Icon />
      //         </SvgIcon>
      //       ),
      //     },
      //     {
      //       title: t(tokens.nav.checkout),
      //       path: paths.checkout,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <LogOut01Icon />
      //         </SvgIcon>
      //       ),
      //     },
      //     {
      //       title: t(tokens.nav.contact),
      //       path: paths.contact,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Mail04Icon />
      //         </SvgIcon>
      //       ),
      //     },
      //     {
      //       title: t(tokens.nav.error),
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <XSquareIcon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: "401",
      //           path: paths["401"],
      //         },
      //         {
      //           title: "404",
      //           path: paths["404"],
      //         },
      //         {
      //           title: "500",
      //           path: paths["500"],
      //         },
      //       ],
      //     },
      //   ],
      // },
    ];
  }, [t]);
};
