export const users = [
  {
    id: "5e86809283e28b96d2d38537",
    avatar: "/assets/avatars/avatar-anika-visser.png",
    email: "admin01@omt.com",
    name: "Anika Visser",
    password: "123456",
    plan: "Premium",
  },
];
